@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Silkscreen', cursive;
}

body {
  margin: 0; 
  color: #335461;
  background-color: #040404;
}


.purchaseGuide {
  color: white;
  background-color: rgba(255, 0, 0, 0.863);
  padding: 10px;
  margin-bottom: 12px;
}

.purchaseinfo {
  color: white;
}

.contributors {
  padding: 20px;
  margin: 5px;
  border: solid 3px #292a2b;
}

.contributorName {
  margin-top: 20px;
  margin-bottom: 0;
  
}

.greenLink {
  color: #06d806;
  text-decoration: none;
  transition: ease-in-out 0.4;

}

.greenLink:hover {
  color: #089c08;
  text-decoration: none;
  transition: ease-in-out 0.4;
}

/* Foooooooter */
/* --------------------------- */

.footer {
  width:100%;
  bottom:0px;
  position: fixed;
}

.footerList {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  overflow: hidden;
  border-top: solid 3px #292a2b;
  background-color: #000000;
  text-decoration: none;

}

.footerListItem {
  float: right;
  color: #606060;
  margin-right: 20px;
  text-decoration: none;
}

.footerListItem:hover {
  text-decoration: none;
  color: #242424;

}


.footerListItemZero {
  float: left;
  color: #858585;
}

.footerList li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* --------------------------- */


.homepage {
  margin-bottom: 30rem;
}

.line {
  background-color: #2c414a;
  border: solid 3px #2c414a;
}
.logoNavBarIcon {
  width: 1.5rem;
  margin-right: 10px;
}

.linkSpan {
  text-decoration: none;
  color: #000000;
}

.logoTXT {
  color: #06d806;
}

.assetInfoBox {
  border: solid 1.7px #2c414a;
  padding: 20px;
  color: #335461;
}

span {
  color: #989A9D;

}

/* 
TEXT 
-----------------------------------------------------
*/

.text-no-margin {
  padding: 0;
  margin: 0;
  font-size: 15px;
  color: #06d806;
  text-decoration: none;
  font-weight: 400;
}

.glow {
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.063), 0 0 10px rgba(255, 255, 255, 0.211), 0 0 15px #06d80692, 0 0 30px #06d80692;

}

.greenTxt {
  color: #06d806;

}

iframe {
  background-color: white;
}

.green-text {
  padding: 0;
  margin-top: 10;
}

h1 {
  color: #06d806;
  padding-top: 30px;

}

h2 {
  color: #06d806;
  padding-top: 30px;
}

h3 {
  color: #989A9D;
}

h4 {
  color: #06d806;
  padding-top: 30px;

}

p {
  color: #989A9D;
}

a {
  color: white;
  text-decoration: none;
}

.navItem {
  transition: ease-in-out 0.4s !important;
}

.navItem:hover {
  color: #06d806 !important;
  transition: ease-in-out 0.4s;
  background-color: #242424;
  border-radius: 5px;
}

.no-decoration {
  text-decoration: none;
}

/*  
-----------------------------------------------------
*/

/* 
DEPOSIT ADDRESS 
-----------------------------------------------------
*/

.depositAddress {
  border: solid 3px #292a2b;
  color: white;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.depositAddress h2 {
  padding-top: 0;
}

.depositAddressText {
  color: #989A9D;
  width: 60%;
}

.depositAddressField { 
  background-color: #0f0f0f;
  color: #06d806;
  padding: 20px;
}

/*  
-----------------------------------------------------
*/


/* 
WALLET ASSETS
-----------------------------------------------------
*/

.Link {
  text-decoration: none;
}

.Wallet_Asset { 
  background-color: #040404;
  border-color: rgba(148, 148, 148, 0.222);
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  text-decoration: none;
  color: black;
  margin-bottom: 25px;
  transition: 0.3s ease-in-out;
  height: 95%;
}

.Wallet_Asset:hover {
  background-color: #3030308f;
  color: #606060;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.015), 0 0 10px #06d806a5, 0 0 15px #06d806a5;
  transition: 0.3s ease-in-out;

}

.Wallet_Asset h3 {
  padding-left: 20px;
}

.Wallet_Asset p {
  padding-left: 20px;
  text-decoration: none;
}

.walletAssetName {
  text-decoration: none;
}

/* 
DISPLAYING ASSETS
-----------------------------------------------------
*/

.assetImage {
  width: 100%;
}

.image-single-nft {
  width: 100%;
  height: auto;
  max-height: 95%;
  min-height: 300px;
  padding: 20px;
  border-radius: 20px;

}

.image-single-nft.arModel {
  /* Specific styles for AR models */
}

.image-single-nft.jsonQuery {
  /* Specific styles for JSON queries */
}


/*  
-----------------------------------------------------
*/


input, select {
  width: 100%;
  padding: 12px 20px;

  border: 1px solid #06d806;
  box-sizing: border-box;
  background-color: rgb(0, 0, 0);
  color: #06d806;
}

.inputFieldField {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
/* 
TABLE 
-----------------------------------------------------
*/
table {
  border: 1px solid #989A9D;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #989A9D;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  border: 1px solid #2c414a;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: left;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/*  
-----------------------------------------------------
*/

/* 
ASSET PAGE
-----------------------------------------------------
*/

.assetPage {
  margin-top: 3rem;
}

.assetPage h1 {
  padding-top: 0px;
}

/* 
-----------------------------------------------------
*/

/* 
BUTTONS
-----------------------------------------------------
*/

.GreenTableButton {
  background-color: #06d806;

  padding-top: 7px;
  padding-bottom: 7px;

  padding-right: 15px;
  padding-left: 15px;

  transition: 0.3s ease-in-out;
  border: none;

}

.GreenTableButton:hover {
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.015), 0 0 10px rgba(255, 255, 255, 0.06), 0 0 15px #06d80692, 0 0 30px #06d80692;
  border: none;

}

.greenButton {
  margin-top: 20px;
  border: none;
  margin-right: 10px;
  background-color: #06d806;

  padding-top: 15px;
  padding-bottom: 15px;

  padding-right: 35px;
  padding-left: 35px;
  	
  transition: 0.3s ease-in-out;

  color: black;
}

.greenButton:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.015), 0 0 10px rgba(255, 255, 255, 0.06), 0 0 15px #06d80692, 0 0 30px #06d80692;
  border: none;
  background-color: #06d806;
  transition: 0.3s ease-in-out;
}

/* 
-----------------------------------------------------
*/



.orderData {
  font-weight: 400;
  color: #ff3030;
}

.orderPData {
  margin-top: 20px;
  font-size: 20px;
}

.important {
  background-color: #858585;
  padding: 10px;
  text-align: center;
  color: white
}

.qrImage {
  align-items: center;
  text-align: center;
  width: 100%;
}

.navbar {
  border-bottom: solid 3px #292a2b;
  margin: 0;
}

/* 
FOOTER
-----------------------------------------------------
*/


/* 
Landing PAGE
-----------------------------------------------------
*/

.header {
  text-align: center;
  margin-top: 10%;

}

.header h1 {
  font-size: 2.9rem;
  color: #06d806;
}

.headerImage {
  width: 20%;
  margin-bottom: 20px;
}
